import Header from "components/header";
import "../App.css";
import Slider from "components/Slider";
import About from "components/About";
import WhyusSection from "components/WhyusSection";
import Services from "components/Services";
import Example from "components/Example";
import Testmonials from "components/Testmonials";
import Team from "components/Team";
import Pricing from "components/Pricing";
import FaqSection from "components/FaqSection";
import Contact from "components/Contact";
import Footer from "components/Footer";
import Projects from "components/Projects";
import { FaWhatsapp } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <Header />
 
      <main class="main">
        <Slider />
        <Projects />
        <Contact />
        <About />
        {/* <WhyusSection/> */}
        {/* <Services/> */}
        {/* <Example/> */}
        <Testmonials />
        {/* <Team/> */}
        {/* <Pricing/> */}
        {/* <FaqSection/> */}
      </main>
      <Footer />
    </>
  );
}

export default Home;
