import Footer from "components/Footer";
import Header from "components/header";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const AboutPage = () => {
  return (
    <>
      <Header />
      <main class="main mt-[75px] lg:mt-[100px]">
        <div className="relative pb-8 ">
          <div className="container pt-16 text-center z-10 relative">
            <div className="">
              <h2 className="text-[28px] lg:text-[36px] font-bold mb-2 text-black">
                {" "}
                درباره ما
              </h2>
            </div>
            <Swiper
              modules={[Autoplay,Pagination]}
              autoplay={{ delay: 4000 }}
              breakpoints={{
                1: { slidesPerView: 1 },
                620: { slidesPerView: 2, spaceBetween: 30 },
              }}
              className="mt-5 clients2"
              pagination={{
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true,
              }}
            >
              <SwiperSlide>
                <img src="/office/1 (1).jpeg" className="w-full rounded" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/office/1 (1).jpg" className="w-full rounded" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/office/1 (2).jpg" className="w-full rounded" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/office/1 (3).JPG" className="w-full rounded" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/office/1 (4).jpg" className="w-full rounded" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/office/1 (5).jpg" className="w-full rounded" />
              </SwiperSlide>
              <div class="swiper-pagination"></div>
            </Swiper>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AboutPage;
