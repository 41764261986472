import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";
import { Link } from "react-router-dom";
import ProjectCard from "./common/ProjectCard";
import { Example_data } from "data/example";

const Projects = () => {
  return (
    <>
      <section id="projects" class="bg-light section clients2 ">
        <div class="container section-title" data-aos="fade-up">
          <span class="description-title">پروژه های پیشنهادی</span>
          <h2>پروژه های پیشنهادی</h2>
        </div>
        <Swiper
          breakpoints={{
            0: { slidesPerView: 1 },
            520: { slidesPerView: 2 },
            786: { slidesPerView: 3 },
            1048: { slidesPerView: 4 },
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 5000 }}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          loop
          spaceBetween={25}
          className="container px-3"
        >
        {Example_data.slice(3,9).map((item)=>(

          <SwiperSlide>
            <ProjectCard data={item} />
          </SwiperSlide>
        ))}
          <div class="swiper-pagination"></div>
        </Swiper>
        <div className="flex justify-center mt-4">
          <Link to={"/projects"} className="btn-brand"> مشاهده همه</Link>
        </div>
      </section>
    </>
  );
};

export default Projects;
