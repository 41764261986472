import React from "react";
import { FaBed, FaMapLocation, FaUser } from "react-icons/fa6";
import { Link } from "react-router-dom";

const ProjectCard = ({ data }) => {
  return (
    <div className="border  bg-white">
      <Link to={`/projects/${data?.company}/${data?.url}`}>
        <img src={data?.cover} className="w-full h-[220px] object-cover" alt="name" />
        <div dir="rtl" className="p-3 text-gray-600">
          <h3 className="font-bold text-[20px] text-black">{data?.name}</h3>
          <p className="mt-1">شروع قیمت از {data?.price} درهم</p>
          <div className="flex gap-3 mt-3 pb-2 text-[12px]">
            <span className="flex gap-1 items-center">
              <FaBed /> {data?.bed}
            </span>
            <span className="flex gap-1 items-center">
              <FaUser />
              {data?.company}
            </span>
            <span className="flex gap-1 items-center">
              <FaMapLocation /> {data?.location}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProjectCard;
