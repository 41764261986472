import React, { useRef, useState } from "react";
import { FaAngleDown, FaEnvelope, FaWhatsapp } from "react-icons/fa6";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { httpReauest } from "api/api";
import { toast } from "react-toastify";
import * as Yup from "yup";
const VITE_SITE_KEY = "6LchGzQqAAAAAACh11TFb0yF1BUb3nQAZMNZUPJD";
const SITE_SECRET = "6LchGzQqAAAAAM1RpZ-oQwac9Bas5x6L19KvPyHL";
const Contact = ({ aboutref, noname }) => {
  const recaptcha = useRef(null);

  const navigate = useNavigate();

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      phone: Yup.string()
        .min(11, "شماره موبایل صحیح نیست")
        .max(11, "شماره موبایل صحیح نیست")
        .required("الزامی است"),
    });
  };

  const formik = useFormik({
    validationSchema: VALUE_SIGN_VALIDATION,
    initialValues: { name: "", phone: "", budget: "", target: "" },
    onSubmit: async (values) => {
      if (recaptcha?.current.getValue() == "") {
        toast.error("من ربات نیستم را تکمیل کنید");
      } else {
        await httpReauest("POST", "/send", values, {}).then((res) => {
          toast.success("اطلاعات ارسال شد");
          navigate("/projects");
          formik.resetForm();
        });
      }
    },
  });

  return (
    <section
      id="contact"
      class="contact bg-transparent section p-0 rounded shadow"
    >
      {!noname && (
        <div class="container section-title" data-aos="fade-up">
          <span class="description-title">ارتباط</span>
          <h2>ارتباط</h2>
          <p>همین حالا با ما ارتباط بگیر</p>
        </div>
      )}

      <div class="container" data-aos="fade-up" data-aos-delay="100">
        {!noname && (
          <div
            dir="rtl"
            class="info-wrap"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="row gy-5">
              <div class="col-lg-4">
                <Link
                  target="_blank"
                  to={"https://wa.me/971508856571"}
                  class="info-item d-flex align-items-center"
                >
                  <i className="flex-shrink-0">
                    <FaWhatsapp size={35} className="flex-shrink-0" />
                  </i>
                  <div>
                    <h3>واتساپ</h3>
                    <p dir="ltr">+971509402839</p>
                  </div>
                </Link>
              </div>

              <Link to={"tel:+971509402839"} class="col-lg-4">
                <div class="info-item d-flex align-items-center">
                  <i class="bi bi-telephone flex-shrink-0"></i>
                  <div>
                    <h3>تماس </h3>
                    <p dir="ltr">+971509402839</p>
                  </div>
                </div>
              </Link>

              <div class="col-lg-4">
                <div class="info-item d-flex align-items-center">
                  <i class="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h3>ایمیل</h3>
                    <p>info@example.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <form
          class="php-email-form"
          data-aos="fade-up"
          data-aos-delay="300"
          dir="rtl"
          onSubmit={formik.handleSubmit}
        >
          <div class="row gy-4 mb-4">
            <div class="col-md-6">
              <input
                type="text"
                name="name"
                id="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                class="form-control"
                placeholder="نام و نام خانوادگی"
              />
              {formik.errors.name && formik.touched.name && (
                <small className="text-danger" style={{ color: "red" }}>
                  {formik.errors.name}
                </small>
              )}
            </div>

            <div class="col-md-6 ">
              <input
                type="text"
                class="form-control"
                name="phone"
                id="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                placeholder="شماره تماس"
                required=""
              />
              {formik.errors.phone && formik.touched.phone && (
                <small className="text-danger" style={{ color: "red" }}>
                  {formik.errors.phone}
                </small>
              )}
            </div>

            <div class="col-md-6 position-relative">
              <FaAngleDown className="left-down" />
              <select
                id="budget"
                name="budget"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.budget}
                class="form-control"
              >
                <option>بودجه پیشنهادی</option>
                <option>زیر یک میلیون درهم</option>
                <option>یک تا دو میلیون درهم</option>
                <option>2 الی 5 میلیون درهم</option>
                <option>5 الی ده میلیون درهم</option>
                <option>10 الی 20 میلیون درهم</option>
                <option>20 الی 50 میلیون درهم</option>
              </select>
            </div>
            <div class="col-md-6 position-relative">
              <FaAngleDown className="left-down" />
              <select
                id="target"
                name="target"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.target}
                class="form-control"
              >
                <option>هدف سرمایه گذاری</option>
                <option>اقساطی</option>
                <option>شخصی</option>
                <option>مهاجرت</option>
              </select>
            </div>
            <div class="col-md-12 text-center d-flex justify-content-center">
              <ReCAPTCHA sitekey={VITE_SITE_KEY} ref={recaptcha} />
            </div>
            <div class="col-md-12 text-center">
              <button type="submit">ارسال</button>
            </div>
          </div>
        </form>
        {noname && (
          <div
            dir="rtl"
            class="info-wrap"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h2 className="text-[24px] lg:text-[32px] font-bold mb-4">
              مراحل شرکت
            </h2>
            <div class="row gy-5">
              <div class="col-lg-4">
                <span class="info-item d-flex align-items-center text-right">
                  <i class="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h3>مرحله اول</h3>
                    <p dir="ltr">اطلاعات تماستون رو بزارید</p>
                  </div>
                </span>
              </div>

              <span class="col-lg-4">
                <div class="info-item d-flex align-items-center text-right">
                  <i class="bi bi-telephone flex-shrink-0"></i>
                  <div>
                    <h3>مرحله دوم</h3>
                    <p dir="ltr">مشاوره تلفنی مشاوران ما</p>
                  </div>
                </div>
              </span>

              <div class="col-lg-4">
                <div class="info-item d-flex align-items-center text-right">
                  <i class="bi bi-airplane flex-shrink-0"></i>
                  <div>
                    <h3>مرحله سوم</h3>
                    <p>بازدید و سرمایه گذاری</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Contact;
