import ProjectCard from "components/common/ProjectCard";
import Footer from "components/Footer";
import Header from "components/header";
import { Example_data } from "data/example";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ProjectsPage = () => {
  return (
    <>
      <Header />
      <Helmet>
        <title>X Realty | Projects</title>
        <meta name="og:title" content="X Realty | Projects" />
      </Helmet>
      <div dir="rtl" className="main">
        <div class="page-title dark-background bg-dark">
          <div class="container">
            <h1>پروژه ها</h1>
            <nav class="breadcrumbs">
              <ol>
                <li>
                  <Link to={"/"}>خانه</Link>
                </li>
                <li class="current">پروژه ها</li>
              </ol>
            </nav>
          </div>
        </div>
        <section id="service-details" class="service-details section">
          <div class="container grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:grid-cols-4 gap-3 lg:gap-4">
            {Example_data.map((item) => (
              <ProjectCard data={item} />
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ProjectsPage;
