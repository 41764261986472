import React from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const comments = [
  {
    name: "MAHENDRA MULCHAND",
    image:
      "https://res.cloudinary.com/dkhns25jh/image/upload/v1724743057/xr_media/mmlv43ocg0pkzcih7sdm.png",
    content:
      "آژانس عالی! من و همسرم با راخی کار می کردیم تا یک خانه جدید در جامعه داماک پیدا کنیم، بسیار لذت بخش بود. از ابتدا پروسسی حرفه ای بود. او واحدهای مختلفی را در مناطق مختلف به ما نشان داد تا اینکه دقیقاً همان چیزی را که به دنبالش بودیم پیدا کردیم. او مطمئن شد که ما همیشه در اولویت هستیم و درک گسترده ای از بازار املاک و مستغلات در دبی داشتیم. راخی فوق العاده صبور و فهمیده بود.",
  },
  {
    name: "MALINI MAHENDRA",
    image:
      "https://res.cloudinary.com/dkhns25jh/image/upload/v1724743579/xr_media/tjr38lo0ucvfk5avonzx.png",
    content:
      "بسیار حرفه ای به درخواست من رسیدگی شد. آفیس هاله بسیار مثبتی داشت! کار فوق العاده، راخی",
  },
  {
    name: "SALMAN AZAM",
    image:
      "https://res.cloudinary.com/dkhns25jh/image/upload/v1724748567/xr_media/evyc0jdsfrfinsm4jtfy.png",

    content:
      "ما با یوسف از بسیاری از املاک بازدید کردیم و او در نشان دادن بسیاری از املاک و مستغلات طبق معیارهای ما بسیار دوستانه و مفید به ما کمک کرد و بخش مهم این است که او هرگز رفتار خود را تغییر نمی دهد حتی اگر شما حتی پس از دیدن چندین ملک چیزی نخرید. با او",
  },
  {
    name: "ASHA MURTHY",
    image:
      "https://res.cloudinary.com/dkhns25jh/image/upload/v1724749255/xr_media/drdhscgjfn7zocddt6gk.png",

    content:
      "شروتی عالی و بسیار قابل اعتماد بود. این یک معامله بین المللی بود که افراد متعددی از کشورهای مختلف در آن شرکت داشتند. او در راس همه ارتباطات بود و ضروری بود. به لطف او - معاملات ما به خوبی انجام شد. او را به شدت توصیه می کنیم.",
  },
];

const Testmonials = () => {
  return (
    <section id="testimonials" class="testimonials section dark-background">
      <img src="/img/testimonials-bg.jpg" class="testimonials-bg" alt="" />

      <div class="container" data-aos="fade-up" data-aos-delay="100">
        <Swiper
          loop
          autoplay={{ delay: 5000 }}
          modules={[Autoplay, Pagination]}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
        >
          {comments.map((item) => (
            <SwiperSlide>
              <div dir="rtl" class="testimonial-item">
                <img src={item.image} class="testimonial-img" alt="" />
                <h3>{item.name}</h3>
                <div class="stars">
                  <i class="bi bi-star-fill"></i>
                  <i class="bi bi-star-fill"></i>
                  <i class="bi bi-star-fill"></i>
                  <i class="bi bi-star-fill"></i>
                  <i class="bi bi-star-fill"></i>
                </div>
                <p>
                  <i class="bi bi-quote quote-icon-right"></i>
                  <span>{item.content}</span>
                  <i class="bi bi-quote quote-icon-left"></i>
                </p>
              </div>
            </SwiperSlide>
          ))}
          <div class="swiper-pagination"></div>
        </Swiper>
      </div>
    </section>
  );
};

export default Testmonials;
