import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const location = useLocation();

  return (
    <header
      id="header"
      class={`${
        show ? "mobile-nav-active" : ""
      } header d-flex align-items-center fixed-top bg-black`}
    >
      <div class="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
        <Link to="/" class="logo d-flex align-items-center">
          <img src="/logo.png" alt="" />
        </Link>

        <nav dir="rtl" id="navmenu" class={`navmenu `}>
          <ul>
            <li>
              <Link to="/" class={location.pathname === "/" ? "active" : ""}>
                خانه
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                class={location.pathname === "/about" ? "active" : ""}
              >
                درباره ما
              </Link>
            </li>
            <li class="dropdown">
              <Link
                className={`${show2 && "active"} ${
                  location.pathname === "/projects" ? "active" : ""
                }`}
              >
                <span>پروژه ها</span>{" "}
                <i
                  onClick={() => setShow2(!show2)}
                  class="bi bi-chevron-down toggle-dropdown"
                ></i>
              </Link>
              <ul className={show2 && "dropdown-active"}>
                <li>
                  <Link to="/projects">همه پروژه ها</Link>
                </li>
                <li>
                  <Link to="/projects/EMAAR">EMAAR</Link>
                </li>
                <li>
                  <Link to="/projects/NAKHEEL">NAKHEEL</Link>
                </li>
                <li>
                  <Link to="/projects/DAMAC">DAMAC</Link>
                </li>
                <li>
                  <Link to="/projects/MERAAS">MERAAS</Link>
                </li>
                <li>
                  <Link to="/projects/DUBAI-PROPERTIES">DUBAI PROPERTIES</Link>
                </li>
                <li>
                  <Link to="/projects/SOBHA">SOBHA</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/">پروژه اقساطی</Link>
            </li>
            <li>
              <Link to="/">گلدن ویزا</Link>
            </li>
            <li>
              <Link to="/">آفر ویژه</Link>
            </li>
            <li>
              <Link to="/">گواهینامه</Link>
            </li>
          </ul>
          <i
            onClick={() => setShow(!show)}
            class={`mobile-nav-toggle d-xl-none bi ${
              !show ? "bi-list" : "bi-x"
            } `}
          ></i>
        </nav>
      </div>
    </header>
  );
};

export default Header;
