export const Example_data = [
  {
    name: "GREENRIDGE",
    url: "greenridge-at-emaar-south",
    price: "2,940,000",
    company: "EMAAR",
    location: "EMAAR SOUTH",
    bed: "4",
    iframe:
      "https://maps.google.com/maps?q=25.129436693764188,55.26148389454595&hl=en&z=14&output=embed",
    type: "خانه های شهری",
    cover: "/projects/greenridge.png",
    images: [
      "/projects/greenridge.png",
      "/projects/greenridge1.png",
      "/projects/greenridge2.png",
    ],
    desc: "استاندارد جدیدی از زندگی خانوادگی را کشف کنید",
    more_desc:
      "به Greenridge در Emaar South خوش آمدید، واحه ای آرام که برای شکوفایی خانواده ها طراحی شده است. گرینریج که در پرطرفدارترین جامعه دبی واقع شده است، از شما دعوت می کند تا ترکیبی هماهنگ از زندگی مدرن، زیبایی طبیعی و امکانات رفاهی مناسب برای خانواده را تجربه کنید. در اینجا، هر لحظه فرصتی برای اتصال، باز شدن، و ایجاد خاطراتی است که تا آخر عمر باقی خواهند ماند.",
  },
  {
    name: "GOLF HILLSIDE",
    url: "golf-hillside-at-dubai-hills-estate",
    price: "1,470,000",
    company: "EMAAR",
    location: "DUBAI HILLS ESTATE",
    bed: "3",
    iframe:
      "https://maps.google.com/maps?q=24.867807107412684,55.15893618772579&hl=en&z=14&output=embed",
    type: "آپارتمان های",
    cover: "/projects/golfhillside (4).jpg",
    images: [
      "/projects/golfhillside (1).jpg",
      "/projects/golfhillside (2).jpg",
      "/projects/golfhillside (3).jpg",
      "/projects/golfhillside (4).jpg",
    ],
    desc: "سبک زندگی لوکس آرام و فضاهای سبز",
    more_desc:
      "تصور کنید که با درخشش ملایم نور خورشید که از پنجره‌هایتان می‌تابد بیدار می‌شوید، با چشم‌اندازهای بدون وقفه از یک زمین گلف سرسبز که فراتر از آن کشیده شده است. در گلف هیل‌ساید، شما فقط زندگی نمی‌کنید—شما شکوفا می‌شوید، در احاطه زیبایی آرام املاک دبی هیل و انرژی یک جامعه مدرن و متصل، با چشم‌اندازهای بدون وقفه از چمن‌های گلف سرسبز و جوامع ویلایی با شکوه.",
  },
  {
    name: "LAVITA",
    url: "lavita-at-the-oasis",
    price: "36,000,000",
    company: "EMAAR",
    location: "THE OASIS",
    bed: "6",
    iframe:
      "https://maps.google.com/maps?q=25.227716646764915,55.34213596528712&hl=en&z=14&output=embed",
    type: "ویلا های",
    cover: "/projects/lavita (1).png",
    images: [
      "/projects/lavita (1).png",
      "/projects/lavita (3).png",
      "/projects/lavita (2).png",
    ],
    desc: "هنر زندگی مهربانانه",
    more_desc:
      "در Lavita، تجمل و آرامش در هم تنیده شده اند تا سبک زندگی متمایز را ایجاد کنند. این محوطه متشکل از 43 عمارت با طراحی استادانه که در The Oasis قرار دارد، عقب نشینی از چیزهای معمولی را ارائه می دهد. در اینجا، جریان ملایم آبراه ها و فضای سبز سرسبز، پناهگاهی را ایجاد می کند که در آن هر لحظه دعوتی برای استراحت و تجدید قوا است.",
  },
  {
    name: "ADDRESS RESIDENCES",
    url: "address-residences-at-dubai-hills-estate",
    price: "1,930,000",
    company: "EMAAR",
    location: "DUBAI HILLS ESTATE",
    bed: "3",
    iframe:
      "https://maps.google.com/maps?q=25.10618,55.24071&hl=en&z=14&output=embed",
    type: "آپارتمان های",
    cover: "/projects/address (1).jpg",
    images: [
      "/projects/address (1).jpg",
      "/projects/address (3).jpg",
      "/projects/address (2).jpg",
    ],
    desc: "فراتر از لوکس، یک سبک زندگی",
    more_desc:
      "قدم به یک پناهگاه که در آن لوکس معاصر با زندگی شهری پرجنب و جوش ترکیب می‌شود. طراحی شده برای سبک‌های زندگی مدرن، اقامتگاه‌های آدرس دبی هیلز استیت بهترین‌ها را در راحتی و ظرافت مسکونی برند ارائه می‌دهد. خانه لوکس شما، به سبک امضایی هتل‌ها و استراحتگاه‌های آدرس، در انتظار شماست.",
  },
  {
    name: "GREEN VISTAS",
    url: "green-vistas",
    price: "1,600,000",
    company: "SOBHA",
    location: "SOBHA HARTLAND 2",
    bed: "2",
    iframe:
      "https://maps.google.com/maps?q=25.17648744060989, 55.3096203576716&hl=en&z=14&output=embed",
    type: "آپارتمان های",
    cover: "/projects/greenvisits.jpg",
    images: ["/projects/greenvisits.jpg", "/projects/greenvisits.webp"],
    desc: "به طرز باشکوهی سبک زندگی شما را بازتعریف می‌کند",
    more_desc:
      'شرکت سوبها ریلتی اکنون پروژه مسکونی جدیدی را در سوبها هارتلند ۲ راه‌اندازی می‌کند. این پروژه لوکس جدید اکنون معرفی شده و انتظار شما به پایان رسیده است. این پروژه بسیار مورد انتظار "گرین ویستاس" نامگذاری شده است که نمونه‌ای کامل از سرسبزی همراه با آپارتمان‌های لوکس است. سوبها گرین ویستاس به شما اقامتگاه لوکسی را همراه با تمام امکانات مدرن موجود در سوبها هارتلند ۲ ارائه خواهد داد. گرین ویستاس همچنین دارای برنامه پرداخت فوق‌العاده‌ای خواهد بود که واقعاً شما را خوشحال خواهد کرد زیرا گزینه‌های پرداخت راحتی را در اینجا پیدا می‌کنید. عجله کنید! این فرصت را غنیمت شمرده و واحدهای مورد نظر خود را در سوبها هارتلند ۲ رزرو کنید.',
  },
  {
    name: "DAMAC RIVERSIDE",
    url: "damac-riverside",
    price: "1,990,000",
    company: "DAMAC",
    location: "DIP",
    bed: "5",
    iframe:
      "https://maps.google.com/maps?q=24.98619,55.23032&hl=en&z=14&output=embed",
    type: "آپارتمان های",
    cover: "/projects/damac (1).webp",
    images: [
      "/projects/damac (1).webp",
      "/projects/damac (2).webp",
      "/projects/damac (3).webp",
    ],
    desc: "معرفی دماک ریورساید، یک جامعه جدید با برنامه‌ریزی اصلی از دماک پراپرتیز",
    more_desc:
      "معرفی دماک ریورساید، یک جامعه جدید با برنامه‌ریزی اصلی از دماک پراپرتیز، یکی از توسعه‌دهندگان پیشرو املاک در دبی. این توسعه استثنایی که در تقاطع خیابان یالایس و جاده امارات واقع شده، ثروتی از امکانات را ارائه می‌دهد و سبک زندگی فوق‌العاده‌ای را وعده می‌دهد. ریورساید تنها ۱۹۰۰ خانه شهری را شامل می‌شود که تجربه زندگی لوکسی را فراهم می‌کند و فرصتی عالی برای سرمایه‌گذاری است. با هر خوشه‌ای که شامل حداکثر چهار خانه شهری است، این جامعه همچنان انحصاری و بدون شلوغی باقی می‌ماند. عدم وجود برج‌های آپارتمانی بلند، همراه با طرح‌های spacious ۴ و ۵ خوابه، محیط زندگی لوکس و آرامی را تضمین می‌کند.",
  },
  {
    name: "THE ACRES",
    url: "the-acres-by-meraas",
    price: "5,090,000",
    company: "MERAAS",
    location: "DUBAILAND",
    bed: "5",
    iframe:
      "https://maps.google.com/maps?q=25.0579,55.28467&hl=en&z=14&output=embed",
    type: "ویلا های",
    cover: "/projects/theacress (1).jpg",
    images: [
      "/projects/theacress (1).jpg",
      "/projects/theacress (2).jpg",
      "/projects/theacress (3).jpg",
    ],
    desc: "ویلاهای لوکس در دبی لند",
    more_desc:
      "ویلاهای مِراس دِ آکرز شامل ویلاهای ۳ تا ۵ خوابه هستند که با اتاق‌های بیرونی و باغ‌های پشتی منحصر به فرد خود شناخته می‌شوند. این ویلاهای معاصر با طراحی باز و پنجره‌های از کف تا سقف که گیاهان جذاب را به فضای داخلی زندگی متصل می‌کند، مشخص می‌شوند. این فلسفه طراحی خانه‌هایی را ایجاد می‌کند که در آن طبیعت بخشی جدایی‌ناپذیر از زندگی روزمره می‌شود.",
  },
  {
    name: "COMO RESIDENCES",
    url: "como-residences",
    price: "21,000,000",
    company: "NAKHEEL",
    location: "man-made island of Palm Jumeirah",
    bed: "6",
    iframe:
      "https://maps.google.com/maps?q=25.11145,55.14570&hl=en&z=14&output=embed",
    type: "آپارتمان های",
    cover: "/projects/nakel (1).webp",
    images: [
      "/projects/nakel (1).webp",
      "/projects/nakel (2).webp",
      "/projects/nakel (1).jpg",
    ],
    desc: "مجتمع کما، برج الهام گرفته از آب در پالم جمیرا",
    more_desc:
      '"کومو ریزیدنس برج الهام گرفته از آب در پالم جمیرا است، در میان استراحتگاه‌های لوکس، گزینه‌های متعدد غذاخوری گران‌قیمت و تعداد زیادی بوتیک‌های باکیفیت. این پروژه آپارتمان‌ها، دوطبقه‌ها و یک پنت‌هاوس انحصاری را ارائه می‌دهد که نمای ۱۸۰ یا ۳۶۰ درجه‌ای از خلیج و افق دبی را فراهم می‌کند."',
  },
];
