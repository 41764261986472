import Header from "components/header";
import "../App.css";
import Slider from "components/Slider";
import About from "components/About";
import WhyusSection from "components/WhyusSection";
import Services from "components/Services";
import Example from "components/Example";
import Testmonials from "components/Testmonials";
import Team from "components/Team";
import Pricing from "components/Pricing";
import FaqSection from "components/FaqSection";
import Contact from "components/Contact";
import Footer from "components/Footer";
import Projects from "components/Projects";
import { FaWhatsapp } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Landing() {
  return (
    <>

      <main class="main">
        <div className="relative pb-8 bg-black">
          <img
            src="https://api.xrealtiyir.com/public/dubai%20(2).jpg"
            className="w-full h-full object-cover absolute left-0 top-0 opacity-75"
            alt="Dubai"
          />
          <div className="container pt-16 text-center z-10 relative">
            <div className="">
              <h2 className="text-[28px] lg:text-[36px] font-bold mb-2 text-white">
                {" "}
                به کمک ما، سریع و مطمئن در دبی سرمایه‌گذاری کنید!
              </h2>
              <p className="lg:text-[18px] mb-4 text-white">
                با اطمینان در بازار ملک دبی سرمایه‌گذاری کنید؛ متخصصان ما در
                کنار شما هستن!
              </p>
            </div>
            <Contact noname />
          </div>
        </div>
        <About noname />
      </main>
      <Footer/>
    </>
  );
}

export default Landing;
