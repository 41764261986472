import Home from "pages/Home";
import "./App.css";
import { createBrowserRouter, Link, RouterProvider } from "react-router-dom";
import ProjectsPage from "pages/ProjectsPage";
import ProjectsCompanyPage from "pages/ProjectsCompanyPage";
import { useEffect } from "react";
import { FaPhone, FaWhatsapp } from "react-icons/fa6";
import ProjectDetailPage from "pages/ProjectDetailPage";
import ScrollToTop from "components/ScrooltoTop";
import Landing from "pages/Landing";
import AboutPage from "pages/AboutPage";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Link
            to={"https://wa.me/+971509402839"}
            className="fixed right-4 bottom-4 bg-green-500 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
            dir="rtl"
          >
            <FaWhatsapp size={30} /> <span className="mt-1">واتساپ</span>
          </Link>
          <Link
            to={"tel:+971509402839"}
            className="fixed right-4 bottom-[4.5rem] bg-slate-800 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
            dir="rtl"
          >
            <FaPhone size={22} /> <span className="mt-1">تماس</span>
          </Link>
          <ScrollToTop />
          <Home />
        </>
      ),
    },
    {
      path: "/landing",
      element: (
        <>
          <Link
            to={"https://wa.me/+971509402839"}
            className="fixed right-4 bottom-4 bg-green-500 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
            dir="rtl"
          >
            <FaWhatsapp size={30} /> <span className="mt-1">واتساپ</span>
          </Link>
          <Link
            to={"tel:+971509402839"}
            className="fixed right-4 bottom-[4.5rem] bg-slate-800 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
            dir="rtl"
          >
            <FaPhone size={22} /> <span className="mt-1">تماس</span>
          </Link>
          <ScrollToTop />
          <Landing />
        </>
      ),
    },
    {
      path: "/about",
      element: (
        <>
          <Link
            to={"https://wa.me/+971509402839"}
            className="fixed right-4 bottom-4 bg-green-500 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
            dir="rtl"
          >
            <FaWhatsapp size={30} /> <span className="mt-1">واتساپ</span>
          </Link>
          <Link
            to={"tel:+971509402839"}
            className="fixed right-4 bottom-[4.5rem] bg-slate-800 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
            dir="rtl"
          >
            <FaPhone size={22} /> <span className="mt-1">تماس</span>
          </Link>
          <ScrollToTop />
          <AboutPage />
        </>
      ),
    },
    {
      path: "/projects",
      element: (
        <>
          <Link
            to={"https://wa.me/+971509402839"}
            className="fixed right-4 bottom-4 bg-green-500 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
            dir="rtl"
          >
            <FaWhatsapp size={30} /> <span className="mt-1">واتساپ</span>
          </Link>
          <Link
            to={"tel:+971509402839"}
            className="fixed right-4 bottom-[4.5rem] bg-slate-800 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
            dir="rtl"
          >
            <FaPhone size={22} /> <span className="mt-1">تماس</span>
          </Link>
          <ScrollToTop />
          <ProjectsPage />
        </>
      ),
    },
    {
      path: "/projects/:name",
      element: (
        <>
          <Link
            to={"https://wa.me/+971509402839"}
            className="fixed right-4 bottom-4 bg-green-500 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
            dir="rtl"
          >
            <FaWhatsapp size={30} /> <span className="mt-1">واتساپ</span>
          </Link>
          <Link
            to={"tel:+971509402839"}
            className="fixed right-4 bottom-[4.5rem] bg-slate-800 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
            dir="rtl"
          >
            <FaPhone size={22} /> <span className="mt-1">تماس</span>
          </Link>
          <ScrollToTop />
          <ProjectsCompanyPage />
        </>
      ),
    },
    {
      path: "/projects/:company/:name",
      element: (
        <>
          <Link
            to={"https://wa.me/+971509402839"}
            className="fixed right-4 bottom-4 bg-green-500 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
            dir="rtl"
          >
            <FaWhatsapp size={30} /> <span className="mt-1">واتساپ</span>
          </Link>
          <Link
            to={"tel:+971509402839"}
            className="fixed right-4 bottom-[4.5rem] bg-slate-800 gap-1  rounded-full flex p-[10px] z-50 text-white items-center "
            dir="rtl"
          >
            <FaPhone size={22} /> <span className="mt-1">تماس</span>
          </Link>
          <ScrollToTop />
          <ProjectDetailPage />
        </>
      ),
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
