import React, { useEffect, useState } from "react";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";

const Slider = () => {
  const [data, setData] = useState([]);
  function getData() {
    const numbers = Array.from({ length: 10 }, (_, i) => i + 1);
    const selectedNumbers = [];
    while (selectedNumbers.length < 4) {
      const randomIndex = Math.floor(Math.random() * numbers.length);
      const randomNumber = numbers[randomIndex];
      if (!selectedNumbers.includes(randomNumber)) {
        selectedNumbers.push(randomNumber);
      }
    }
    setData(selectedNumbers);
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <section id="hero" class="hero section dark-background">
        <Swiper
          modules={[Autoplay, EffectFade]}
          autoplay={{ delay: 4000 }}
          effect="fade"
          className="slider"
        >
          {data.map((item) => (
            <SwiperSlide>
              <img
                src={`https://api.xrealtiyir.com/public/dubai (${item}).jpg`}
                alt=""
                data-aos="fade-in"
                className="w-full h-full object-cover"
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <div
          class="container text-center"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div dir="rtl" class="row justify-content-center">
            <div class="col-lg-8">
              <h2> به کمک ما، سریع و مطمئن در دبی سرمایه‌گذاری کنید!</h2>
              <p>
                با اطمینان در بازار ملک دبی سرمایه‌گذاری کنید؛ متخصصان ما در
                کنار شما هستن!
              </p>
              <a href="#contact" class="btn-get-started">
                شروع کنید
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Slider;
